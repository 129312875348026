import { useEffect, useState } from "react";
import { getMe } from "../../services/dataService";
import { useNavigate } from "react-router-dom";

import {
  CancelCircleIcon,
  CheckmarkCircle02Icon,
} from "../../components/Icons";
import { Button, Spin } from "antd";

export default function PaymentStatus() {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  // Wait for 10 seconds for backend to update the subscription, keep polling every 1 second
  useEffect(() => {
    let timeout = null;
    timeout = setTimeout(() => {
      clearInterval(interval);
      setLoading(false);
    }, 10000);

    const interval = setInterval(() => {
      getMe().then((res) => {
        if (res.subscription) {
          setSubscription(res.subscription);
          clearInterval(interval);
          clearTimeout(timeout);
          setLoading(false);
        }
      });
    }, 1000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-[70vh]">
          <Spin size="large" />
        </div>
      ) : subscription ? (
        <div className="flex justify-center items-center h-[70vh]">
          <div className="flex flex-col items-center">
            <CheckmarkCircle02Icon className="w-32 h-32 text-green-500 mb-8" />

            <div className="flex flex-row items-center">
              <h1 className="text-2xl font-bold">Payment Successful</h1>
            </div>
            <p className="text-lg">
              You have successfully subscribed to the plan. Redirecting to home in 5 seconds...
            </p>

            <Button
              type="link"
              onClick={() => {
                // navigate("/");
                // window.location.href = "/chat";
                // window.location.reload();
                // navigate("/chat");
                window.open("/chat", "_self");
              }}
            >
              Redirect Now
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-[70vh]">
          <div className="flex flex-col items-center gap-4">
            <CancelCircleIcon className="w-32 h-32 text-red-500 mb-8" />

            <div className="flex flex-row items-center">
              <h1 className="text-2xl font-bold">Payment Failed</h1>
            </div>
            <p className="text-lg">
              Failed to update subscription. Please contact support at
              <Button
                type="link"
                onClick={() => {
                  window.location.href = "mailto:info@inspiredschoolsolutions.com";
                }}
                className="text-blue-500 text-lg"
              >
                info@inspiredschoolsolutions.com
              </Button>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
