import { useMutation } from "react-query";
import { PaymentServices } from "../services/dataService";
import BlurFade from "./ui/blurFade";
import { Link } from "react-router-dom";
import { useAuth } from "../authContext";
import { useState } from "react";
import { Button } from "antd";

const CheckmarkCircle02Icon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    color={"#000000"}
    fill={"none"}
    {...props}
  >
    <path
      opacity="0.4"
      d="M12 22.75C6.06294 22.75 1.25 17.9371 1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7372 9.67573C17.1103 9.26861 17.0828 8.63604 16.6757 8.26285C16.2686 7.88966 15.636 7.91716 15.2628 8.32428L10.4686 13.5544L8.70711 11.7929C8.31658 11.4024 7.68342 11.4024 7.29289 11.7929C6.90237 12.1834 6.90237 12.8166 7.29289 13.2071L9.79289 15.7071C9.98576 15.9 10.249 16.0057 10.5217 15.9998C10.7944 15.9938 11.0528 15.8768 11.2372 15.6757L16.7372 9.67573Z"
      fill="currentColor"
    />
  </svg>
);

export const Paywall = () => {
  const auth = useAuth();
  const [selectedPlan, setSelectedPlan] = useState(null);

  const checkoutUrlMutation = useMutation(
    async (planId) => {
      setSelectedPlan(planId);
      const data = {
        planId,
        email: auth.user.email,
      };
      const res = await PaymentServices.getChargebeeCheckoutLink(data);
      return res.url;
    },
    {
      onSuccess: (data) => {
        window.open(data, "_blank");
      },
    }
  );

  return (
    <>
      <BlurFade delay={0.2} duration={0.35} inView={true}>
        <div className="max-w-screen-xl flex flex-col mx-auto relative gap-8 md:h-[80vh] items-center">
          <div className="text-center text-4xl font-semibold text-endeavour-600 mt-12">
            Select a plan
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center gap-6 mt-4 md:px-24  ">
            <div className="flex flex-col rounded-xl bg-white basis-1/3 md:h-[24rem] p-3 px-8">
              <p className="text-left text-2xl text-neutral-600 font-medium mt-4 text-center">
                Single User Monthly
              </p>

              <div className="flex flex-col items-left">
                <div className="text-left text-lg text-endeavour-600 mt-10 flex gap-2  items-end">
                  <span className="text-4xl font-semibold"> $29.99 </span>{" "}
                  <span className="text-endeavour-600 text-sm pb-1">
                    per month
                  </span>
                </div>
              </div>
              <div className="text-base text-neutral-600 text-left mt-8 h-20">
                For a sole school leader (e.g., superintendent, principal, or
                vice principal).
              </div>

              {/* <div className="flex flex-col items-left mt-10 h-36">
                <p className="text-neutral-600 text-base inline-flex gap-2">
                  <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                  Access to all platform features.
                </p>
                <p className="text-neutral-600 text-base mt-2  inline-flex gap-2">
                  <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                  Customized setup & training.
                </p>
                <p className="text-neutral-600 text-base mt-2  inline-flex gap-2">
                  <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                  On-demand technical support.
                </p>
              </div> */}

              <div className="flex flex-col items-center mt-10 bottom-0">
                <Button
                  className="border border-endeavour-500 transition hover:border-endeavour-600 text-endeavour-600 font-bold py-2 px-4 rounded-xl w-full h-auto"
                  onClick={() =>
                    checkoutUrlMutation.mutate("single_user_monthly_plan")
                  }
                  loading={
                    checkoutUrlMutation.isLoading &&
                    selectedPlan === "single_user_monthly_plan"
                  }
                >
                  Start 2-weeks free trial
                </Button>
              </div>
            </div>
            <div className="flex flex-col rounded-xl bg-white basis-1/3  md:h-[24rem] p-3 px-8 ">
              <p className="text-left text-2xl text-neutral-600 font-medium mt-4 text-center">
                Single User Annually
              </p>

              <div className="flex flex-col items-left">
                <div className="text-left text-lg text-endeavour-600 mt-10 flex gap-2">
                  <span className="text-4xl font-semibold"> $23.99 </span>{" "}
                  <span className="text-endeavour-600 text-sm">
                    per month, billed annually $287.88
                  </span>
                </div>
              </div>
              <div className="text-base text-neutral-600 text-left mt-8 h-20">
                For a sole school leader (e.g., superintendent, principal, or
                vice principal).
              </div>

              {/* <div className="flex flex-col items-left mt-10 h-36">
                <p className="text-neutral-600 text-base inline-flex gap-2">
                  <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                  Access to all platform features.
                </p>
                <p className="text-neutral-600 text-base mt-2  inline-flex gap-2">
                  <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                  Customized setup & training.
                </p>
                <p className="text-neutral-600 text-base mt-2  inline-flex gap-2">
                  <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                  On-demand technical support.
                </p>
              </div> */}

              <div className="flex flex-col items-center mt-10 bottom-0">
                  <Button
                  className="border border-endeavour-500 transition hover:border-endeavour-600 text-endeavour-600 font-bold py-2 px-4 rounded-xl w-full h-auto"
                  onClick={() =>
                    checkoutUrlMutation.mutate("single_user_yearly_plan")
                  }
                  loading={
                    checkoutUrlMutation.isLoading &&
                    selectedPlan === "single_user_yearly_plan"
                  }
                >
                  Start 2-weeks free trial
                </Button>
              </div>
            </div>
            <div className="flex flex-col rounded-xl bg-white basis-1/3  md:h-[24rem] p-3 px-8 ">
              <p className="text-left text-2xl text-neutral-600 font-medium mt-4 text-center">
                Multiple Users
              </p>

              <div className="flex flex-col items-left">
                <p className="text-left text-lg text-endeavour-600 mt-10 h-10">
                  {/* <span className="text-3xl font-semibold"> $9.99 </span>{" "} */}
                  <span className="text-endeavour-600 text-xl font-semibold text-center">
                  Custom Pricing for multiple users
                  </span>
                </p>
              </div>
              <div className="text-base text-neutral-600 text-left mt-8 h-20 ">
                For multiple school leaders or other staff occupying the same
                building.
              </div>

              {/* <div className="flex flex-col items-left mt-10 md:h-36">
                <p className="text-neutral-600 text-base inline-flex gap-2">
                  <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                  Access to all platform features.
                </p>
                <p className="text-neutral-600 text-base mt-2  inline-flex gap-2">
                  <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                  Customized setup & training.
                </p>
                <p className="text-neutral-600 text-base mt-2  inline-flex gap-2">
                  <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                  On-demand technical support.
                </p>
                <p className="text-neutral-600 text-base mt-2  inline-flex gap-2">
                  <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                  Free Upgrades to new features.
                </p>
                <p className="text-neutral-600 text-base mt-2  inline-flex gap-2">
                  <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                  Custom data integration
                </p>
              </div> */}

              <div className="flex flex-col items-center mt-10 bottom-0">
                <Link to="/contact" className="w-full">
                  <button className="border border-endeavour-500 transition hover:border-endeavour-600 text-endeavour-600 font-bold py-2 px-4 rounded-xl w-full">
                    Contact Us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </BlurFade>
    </>
  );
};
